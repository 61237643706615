import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'app/user';
import { ReactComponent as Pointer } from 'assets/svg/chevron-down.svg';
import userImg from 'assets/images/user.png';
import useDetectClose from 'hooks/useDetectClose';

const Header = () => {
  const navigate = useNavigate();
  const { user, logout } = useUser();
  const [userName, setUserName] = useState('');

  const [myPageIsOpen, myPageRef, myPageHandler] = useDetectClose(false);

  useEffect(() => {
    getUserName();
  }, [user]);

  const getUserName = async () => {
    const name = await localStorage.getItem('name');
    setUserName(name);
  };

  const goToAccount = () => {
    navigate('/account');
  };

  const onLogout = () => {
    logout();
  };

  return (
    <UserWrapper onClick={myPageHandler} ref={myPageRef}>
      <Account>
        <User src={userImg} alt="" />
        <span
          style={{
            fontSize: '14px',
            marginLeft: '14px',
            color: '#232D42',
            lineHeight: '20px',
          }}
        >
          {userName}님
          <br />
          <span style={{ color: '#8F9BBA' }}>안녕하세요, 반갑습니다.</span>
        </span>
      </Account>
      <Pointer className="pointer" />
      <Container className="dropdown" isDroped={myPageIsOpen}>
        {/* NOTE: 계정설정은 추후에 진행한다. */}
        {/* <Category onClick={goToAccount}>계정설정</Category> */}
        <Category onClick={onLogout}>로그아웃</Category>
      </Container>
    </UserWrapper>
  );
};

const UserWrapper = styled.div`
  position: relative;
  position: fixed;
  top: 28px;
  right: 40px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  background-color: #fff;
  border-radius: 30px;
  z-index: 100;
  padding: 15px;
  box-shadow: 5px 5px 5px #eee;

  .pointer {
    transition: all 0.5s ease-in;
  }
  &:hover .pointer {
    transform: rotate(-180deg);
    transition: all 0.5s ease-in;
  }
`;

const User = styled.img`
  width: 40px;
  height: 40px;
`;

const Account = styled.div`
  min-height: 30px;
  display: flex;
  align-items: center;

  &:hover {
    font-weight: bold;
  }
`;

const Container = styled.div`
  display: ${({ isDroped }) => (isDroped ? 'block' : 'none')};
  background-color: #fff;
  position: absolute;
  top: 75px;
  right: 5px;
  width: 210px;
  text-align: right;
  box-shadow: 5px 5px 5px #eee;
  border-radius: 20px;
  padding: 10px;
`;

const Category = styled.div`
  width: 100%;
  font-size: 15px;
  line-height: 30px;
  padding-right: 10px;
  border-radius: 6px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #5d42ff14;
    color: #644ded;
    font-weight: 700;
  }
`;

export default Header;
