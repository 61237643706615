import axios from 'axios';
import { apis } from 'utils/constants';

const getHeaders = async () => {
  if (!localStorage.getItem('token')) {
    const startIndex = window.location.href.lastIndexOf('/') + 1;
    const token = window.location.href.slice(startIndex);
    return {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${token}`,
    };
  } else {
    const token = await localStorage.getItem('token');
    return {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${token}`,
    };
  }
};

const getQueryParams = (params) => {
  let ret = [];
  for (const k in params) {
    if (params[k]) {
      ret.push(`${k}=${encodeURIComponent(params[k])}`);
    }
  }
  return ret.join('&');
};

const doRequest = async (requestParams) => {
  /* eslint-disable-next-line no-console */
  // console.log(JSON.stringify(requestParams, null, 2));

  let { method, url, params, contentType, responseType } = requestParams;

  let ret = null;
  try {
    if (method === 'get') {
      if (responseType === 'blob') {
        ret = await axios.get(`${url}?${getQueryParams(params)}`, {
          withCredential: true,
          headers: await getHeaders(),
          responseType: 'blob',
        });
      } else {
        ret = await axios.get(`${url}?${getQueryParams(params)}`, {
          withCredential: true,
          headers: await getHeaders(),
        });
      }
    } else if (method === 'post') {
      if (contentType === 'multipart/form-data') {
        const headers = await getHeaders();
        ret = await axios.post(url, params, {
          withCredential: true,
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
            accept: 'application/json',
          },
        });
      } else {
        ret = await axios.post(url, params, {
          withCredential: true,
          headers: await getHeaders(),
        });
      }
    } else if (method === 'put') {
      ret = await axios.put(url, params, {
        withCredential: true,
        headers: await getHeaders(),
      });
    } else if (method === 'del') {
      ret = await axios.delete(url, {
        withCredential: true,
        headers: await getHeaders(),
        params,
      });
    } else {
      throw new Error(`Not Implemented`);
    }
    /* eslint-disable-next-line no-console */
    // console.log(ret.data);
    // if (ret.data.statusCode === 401) {
    //   localStorage.clear();
    //   window.open(`${apis.url}/login`, '_self');
    // }
    return ret.data;
  } catch (e) {
    /* eslint-disable-next-line no-console */
    // console.log(JSON.stringify(requestParams, null, 2));
    /* eslint-disable-next-line no-console */
    // console.log(JSON.stringify(e.response?.data || {}, null, 2));
    throw e;
  }
};

const postAccountSignup = async (params) => {
  return await doRequest({
    method: `post`,
    url: `${apis.account.signup}`,
    params: params,
  });
};

const postAccountSignin = async (params) => {
  return await doRequest({
    method: `post`,
    url: `${apis.account.signin}`,
    params: params,
  });
};

const getAccountUser = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.account.user}`,
    params: params,
  });
};

const postAccountCheckPassword = async (params) => {
  return await doRequest({
    method: `post`,
    url: `${apis.account.password.check}`,
    params: params,
  });
};

const postAccountUpdatePassword = async (params) => {
  return await doRequest({
    method: `post`,
    url: `${apis.account.password.update}`,
    params: params,
  });
};

const postAccountGeneratePassword = async (params) => {
  return await doRequest({
    method: `post`,
    url: `${apis.account.password.generate}`,
    params: params,
  });
};

const postAccountGeneratePasswordToken = async (params) => {
  return await doRequest({
    method: `post`,
    url: `${apis.account.password.generate}${params.token}`,
    params: params,
  });
};

const delAccountDelete = async (params) => {
  return await doRequest({
    method: `del`,
    url: `${apis.account.delete}`,
    params: params,
  });
};

const getEstateDataList = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.analysis.estate.list}`,
    params: params,
  });
};

const getEstateAddrList = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.analysis.estate.addr}`,
    params: params,
  });
};

const getEstateDate = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.analysis.estate.date}`,
    params: params,
  });
};

export {
  postAccountSignup,
  postAccountSignin,
  getAccountUser,
  postAccountCheckPassword,
  postAccountUpdatePassword,
  postAccountGeneratePassword,
  postAccountGeneratePasswordToken,
  delAccountDelete,
  getEstateDataList,
  getEstateAddrList,
  getEstateDate,
};
