import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { menu } from 'utils/constants';
import PrivateLayout from 'hoc/PrivateLayout';
import EstateObjectTable from 'pages/analysis/analysis/EstateObjectTable';

const Private = () => {
  return (
    <Routes>
      <Route element={<PrivateLayout />}>
        <Route
          path={menu.analysis.estateObjectList.link}
          element={<EstateObjectTable />}
        />
        <Route
          path="*"
          element={<Navigate to={menu.analysis.estateObjectList.link} />}
        />
      </Route>
    </Routes>
  );
};

export default React.memo(Private);
