// import { isProductionMode } from 'utils/utils';

const getBaseUrl = () => {
  return 'https://api.nanumprice.kr';
};

const base = getBaseUrl();

const apis = {
  url: base,
  account: {
    signup: `${base}/api/admin/account/signup`,
    signin: `${base}/api/admin/account/signin`,
    user: `${base}/api/admin/account/user`,
    password: {
      check: `${base}/api/admin/account/check/password`,
      update: `${base}/api/admin/account/update/password`,
      generate: `${base}/api/admin/account/generate/password`,
    },
    delete: `${base}/api/admin/account/delete`,
  },
  data: {
    analysis: {
      estate: {
        addr: `${base}/api/data/analysis/estate/addr/list`,
        list: `${base}/api/data/analysis/estate/list`,
        date: `${base}/api/data/analysis/estate/date`,
      },
    },
  },
};

export { apis };
