import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { SubTitle } from 'components/atoms';
import { PropContextProvider } from 'context/PropContext';
import DataList from 'components/templates/layout/DataList';
import EmptyData from 'components/templates/layout/EmptyData';
import useTable from 'hooks/useTable';
import {
  EstateObjectTableCols,
  EstateObjectTableSizeRules,
  EstateObjectTableSortFields,
  EstateObjectTableSortRules,
} from './EstateObjectTableConst';
import SearchEstateObjectForm from 'components/templates/layout/SearchEstateObjectForm';
import {
  getTransformedPriceValue,
  getTransformedPtValue,
  getTransformedRatioValue,
} from 'utils/utils';
import { getEstateDate, getEstateDataList } from 'utils/api';

import NaverLandLink from 'assets/images/naver.land.png';
import SelectSizeSortOption from 'components/templates/layout/SelectSizeSortOption';
import TrendChartBox from 'components/blocks/box/TrendChartBox';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Asia/Seoul');

const EstateObjectTable = () => {
  // 1. state & hook
  const [empty] = useState(false);
  const [option, setOption] = useState({
    sido: null,
    gungu: null,
    dong: null,
    spc_type_cd: null,
    tot_hseh_cnt: null,
    aprv_year: null,
    type1_count: null,
    upper_prc_info: null,
    lower_prc_info: null,
    gap: null,
    is_prc_cnt: null,
    prc_desc_ratio: null,
    type2_ratio: null,
    page: 1,
    size: 10,
  });
  const [EstateObjectListData, setEstateObjectListData] = useState({
    total: 0,
    items: [],
  });
  const [EstateDate, setEstateDate] = useState();
  const [EstaetTotalCnt, setEstateTotalCnt] = useState(0);
  const EstateTable = useTable({
    columns: EstateObjectTableCols,
    initData: EstateObjectListData.items,
  });
  const [EstateHoverIdx, setEstateHoverIdx] = useState(-1);
  const hasTrendChartBoxCols = [
    'type1_max_prc_info',
    'type1_min_prc_info',
    'type2_max_prc_info',
    'type2_min_prc_info',
  ];
  const view = useRef();

  // 2. useEffect
  useEffect(() => {
    getEstateObjectList();
  }, [option]);

  useEffect(() => {
    getEstateObjectDate();
  }, []);

  // 3. handler
  const handleOption = (changeData) => {
    const page = changeData?.page ? changeData.page : 1;
    setOption({ ...option, page, ...changeData });
  };

  // 4. table style
  EstateTable.convert.setHead = (item) => {
    const { label } = item;
    return <div style={{ alignItems: 'center' }}>{label}</div>;
  };

  EstateTable.convert.setHeadStyle = () => {
    return {
      fontSize: '10pt',
      fontWeight: 500,
      color: '#A3AED0',
      backgroundColor: '#fff',
      border: '0px solid #fff',
    };
  };

  EstateTable.convert.setCol = (item) => {
    const { rowIdx, colName, rowItem, value } = item;
    if (colName === 'atcl_url') {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <a
            href={value.toLocaleString() || '-'}
            target="_blank"
            rel="noreferrer"
          >
            <Icon src={NaverLandLink} alt="" />
          </a>
        </div>
      );
    } else if (hasTrendChartBoxCols.includes(colName)) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            color: '#2B3674',
          }}
          onMouseOver={() => {
            setEstateHoverIdx(rowIdx);
          }}
        >
          <div>{value.toLocaleString() || '-'}</div>
          {rowItem?.analysis?.type1_min_prc_info?.length > 0 && (
            <TrendChartBox item={rowItem} col={colName} />
          )}
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            color: '#2B3674',
          }}
          onMouseOver={() => {
            setEstateHoverIdx(rowIdx);
          }}
        >
          {value.toLocaleString() || '-'}
        </div>
      );
    }
  };

  EstateTable.convert.setColStyle = (item) => {
    const { colIdx, rowIdx } = item;
    return {
      padding: '0.7rem 0.75rem',
      cursor: 'pointer',
      backgroundColor: EstateHoverIdx === rowIdx ? '#F6F6FC' : '#FFF',
      color: '#2B3674',
      fontWeight: 600,
      textAlign: 'center',
      borderRight: [0, 2, 4, 6, 8, 10, 12].includes(colIdx)
        ? '1px dashed #A3AED0'
        : '0px',
    };
    // }
  };

  // 5. api wrapper
  const getEstateObjectList = async () => {
    const ret = await getEstateDataList({
      ...option,
    });
    const target = ret?.results;
    //
    setEstateTotalCnt(target?.total);
    setEstateObjectListData({
      total: target?.total,
      items: target?.items.map((e) => {
        return {
          ...e,
          tot_hseh_cnt: e.tot_hseh_cnt
            ? `${e.tot_hseh_cnt}세대`
            : e.tot_hseh_cnt,
          spc_type_cd: getTransformedPtValue(e.spc_type_cd),
          global_max_prc_info: getTransformedPriceValue(e.global_max_prc_info),
          type1_max_prc_info: getTransformedPriceValue(e.type1_max_prc_info),
          type1_min_prc_info: getTransformedPriceValue(e.type1_min_prc_info),
          type2_max_prc_info: getTransformedPriceValue(e.type2_max_prc_info),
          type2_min_prc_info: getTransformedPriceValue(e.type2_min_prc_info),
          prc_desc_ratio: getTransformedRatioValue(e.prc_desc_ratio),
          gap: getTransformedPriceValue(e.gap),
          type2_ratio: getTransformedRatioValue(e.type2_ratio),
          prc_desc: getTransformedPriceValue(e.prc_desc),
          prc_info: getTransformedPriceValue(e.prc_info),
          aprv_year: e.aprv_year
            ? `${parseInt(moment().format('YYYY')) - e.aprv_year}년`
            : e.aprv_year,
          analysis: e.analysis,
        };
      }),
    });
  };

  const getEstateObjectDate = async () => {
    const ret = await getEstateDate();
    const target = ret?.results;
    setEstateDate(target?.date);
  };

  // 6. render
  return (
    <Container ref={view}>
      <H1>데이터 현황 ({EstateDate})</H1>
      <H2>부동산 매물 데이터</H2>

      {/* TODO: 분석날짜 넣어주기 */}
      {!empty ? (
        <>
          <div
            style={{
              height: 2,
              border: '1px dashed #C6C6C6',
            }}
          />
          {/* 필터 */}
          <Section style={{ overflowX: 'auto' }}>
            <PropContextProvider
              value={{
                handleOption,
              }}
            >
              <SearchEstateObjectForm />
            </PropContextProvider>
          </Section>
          {/* 표 */}
          <Section style={{ boxShadow: '5px 5px 5px #eee' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                overflowX: 'auto',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <SubTitle style={{ display: 'flex', flexDirection: 'row' }}>
                  부동산 매물 분석 현황 (총 {EstaetTotalCnt}개의 매물)
                </SubTitle>
                <Caution>
                  *국토교통부 실거래가 공개시스템(2019년 ~ 2022년)의 데이터를
                  사용했습니다.
                </Caution>
              </div>

              <PropContextProvider
                value={{
                  rules: EstateObjectTableSortRules,
                  fields: EstateObjectTableSortFields,
                  sizeRules: EstateObjectTableSizeRules,
                  handleOption,
                }}
              >
                <Section style={{ padding: '0px', flexDirection: 'row' }}>
                  <SelectSizeSortOption />
                </Section>
              </PropContextProvider>
            </div>
            <PropContextProvider
              value={{
                table: EstateTable,
                data: EstateObjectListData,
                option: option,
                handleOption: handleOption,
              }}
            >
              <Section style={{ padding: '0px' }}>
                <DataList />
              </Section>
            </PropContextProvider>
          </Section>
        </>
      ) : (
        <div>
          <EmptyData />
        </div>
      )}
    </Container>
  );
};

// styled

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 15px;
`;

const H1 = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #707eae;
  line-height: 20px;
  padding-left: 10px;
`;

const H2 = styled.div`
  font-size: 30px;
  color: #2b3674;
  font-weight: 800;
  padding-left: 10px;
  margin-bottom: 40px;
  margin-top: -20px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  background-color: white;
  border-radius: 16px;
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 5px;
`;

const Caution = styled.div`
  font-size: 12px;
  color: #a3aed0;
  margin-top: 0.8rem;
`;

export default EstateObjectTable;
