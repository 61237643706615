import React, { useState } from 'react';
import styled from 'styled-components';
import { Tr } from 'components/atoms';
import { DataTable, Pagination } from 'components/blocks';
import { usePropContext } from 'context/PropContext';
import EmptyData from './EmptyData';

const DataList = () => {
  const { table, option, data, handleOption } = usePropContext();
  const [hide, setHide] = useState(false);

  return (
    <Container>
      <DataTable
        head={
          <Tr style={{ border: '0px solid #fff' }}>
            {table?.headProp?.map((thead) => {
              if (thead?.len !== -1) {
                return (
                  <th
                    key={thead.id}
                    style={table?.convert.getHeadStyle(thead)}
                    colSpan={thead?.len}
                  >
                    {table?.convert.getHead(thead)}
                  </th>
                );
              }
            })}
          </Tr>
        }
        body={
          table?.bodyProp && table.bodyProp?.length > 0 ? (
            table?.bodyProp?.map((row, ridx) => {
              return (
                <tr key={ridx.toString()} style={{ border: '0px solid #fff' }}>
                  {row.map((col, cidx) =>
                    table?.convert.getRowSpan(col) === 0 ? null : (
                      <td
                        key={cidx.toString()}
                        rowSpan={table?.convert.getRowSpan(col)}
                        style={table?.convert.getColStyle(col)}
                      >
                        {table?.convert.getCol(col)}
                      </td>
                    ),
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={table?.headProp?.length}
                style={{ textAlign: 'center' }}
              >
                <EmptyData type="none" />
              </td>
            </tr>
          )
        }
      />
      {data?.total / option?.size > 1 && (
        <>
          <Line />
          <Pagination
            total={data?.total}
            limit={option?.size}
            current={option?.page}
            onChange={(page) => handleOption({ page })}
          />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.8rem;
`;

const Line = styled.div`
  height: 20px;
`;

const Info = styled.img`
  width: 12px;
  height: 12px;
  margin-left: 4px;
`;

export default DataList;
