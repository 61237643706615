const emptyObj = (obj) => Object.keys(obj).length === 0;

const mergeRow = (tableBody, mergeCol) => {
  try {
    if (mergeCol) {
      let result = '';
      let data = [...tableBody];
      mergeCol.forEach((colName) => {
        data = data.map((item) => ({
          ...item,
          rowSpan: { ...item.rowSpan, [colName]: 1 },
        }));

        let colItem = '';
        let colCheck = false;

        for (let i = 0; i < tableBody.length; i += 1) {
          const mergeItem = tableBody[i][colName];
          const count = 1;
          let check = false;
          colCheck = false;

          if (colItem !== mergeItem) {
            colItem = mergeItem;
            colCheck = true;
          }

          if (colCheck) {
            for (let j = i + 1; j < tableBody.length; j += 1) {
              const item = tableBody[j][colName];
              const startIdx = i + 1 === j;

              if (startIdx && mergeItem === item) {
                check = true;
              }

              if (check && mergeItem === item) {
                data[j].rowSpan[colName] = 0;
                data[i].rowSpan[colName] += count;
              } else {
                check = false;
              }
            }
          }
        }
        result = data;
      });
      return result;
    }
    return tableBody;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return tableBody;
  }
};

const isEmpty = (value) => {
  if (
    value === '' ||
    value === null ||
    value === undefined ||
    (value !== null && typeof value == 'object' && !Object.keys(value).length)
  ) {
    return true;
  } else {
    return false;
  }
};

const pageSetting = (totalCnt, currentPage = 1, pageSize = 10, range = 10) => {
  const totalPages = Math.ceil(totalCnt / pageSize);
  let startPage, endPage;

  if (totalPages <= range) {
    startPage = 1;
    endPage = totalPages;
  } else {
    const centerPage = Math.round(range / 2);
    const rangePage = Math.floor(range / 2);

    if (currentPage <= centerPage) {
      startPage = 1;
      endPage = range;
    } else if (currentPage + rangePage >= totalPages) {
      startPage = totalPages - rangePage * 2 - 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - rangePage;
      endPage = currentPage + rangePage;
    }
  }

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalCnt) || pageSize;

  const pages = [...Array(endPage - startPage + 1).keys()].map(
    (i) => startPage + i,
  );

  return {
    totalPages: totalPages,
    startIndex: startIndex,
    endIndex: endIndex,
    pages: pages,
  };
};

const isProductionMode = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return true;
  } else {
    return false;
  }
};

const getTransformedPriceValue = (value) => {
  // none-filter
  if (value == -1) {
    return `없음`;
  }

  const ret = value ? value / 10000 : null;
  if (ret) {
    return `${ret}억`;
  } else {
    return ret;
  }
};

const getTransformedPtValue = (value) => {
  // none-filter
  if (value == -1) {
    return `없음`;
  }

  if (value.includes('pt')) {
    const ret = value.split('pt')[0];
    return `${ret}평형`;
  } else {
    return value;
  }
};

const getTransformedRatioValue = (value) => {
  // none-filter
  if (value == -1) {
    return `없음`;
  }

  const ret = value ? (value * 100).toFixed(0) : null;
  if (ret) {
    return `${ret}%`;
  } else {
    return ret;
  }
};

export {
  emptyObj,
  mergeRow,
  isEmpty,
  pageSetting,
  isProductionMode,
  getTransformedPriceValue,
  getTransformedPtValue,
  getTransformedRatioValue,
};
