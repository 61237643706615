import React from 'react';
import styled, { css } from 'styled-components';

export default function DefaultButton(props) {
  return <Button {...props} />;
}

const Button = styled.button`
  color: #fff;
  background-color: #8800ff;
  border: 1px solid #8800ff;
  box-shadow: 5px 5px 5px #eee;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2.2rem;

  ${({ outline }) => {
    if (outline) {
      return css`
        color: #343a40;
        background-color: transparent;
        border-color: #343a40;
      `;
    }
  }}

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
  }

  &:disabled {
    opacity: 0.65;
    cursor: default;
  }
`;
