import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Login from 'pages/login';
import Password from 'pages/password';
import ChangePassword from 'pages/password/changePassword';
import axios from 'axios';
import { store } from 'app/store';
import { useUser } from 'app/user';
import { useLoading } from 'app/loading';
import { pending, success, fail } from 'app/loading/loading.slice';
import { Modal } from 'hoc/modal';
import Private from 'routes/Private';
import ScrollToTop from 'hoc/ScrollToTop';
import { Spin } from 'components/atoms';
import { Toast } from 'components/blocks';
import { menu } from 'utils/constants';
import { userSignOutSuccess } from 'app/user/user.slice';
import { ChannelService, CHANNEL_PLUGIN_KEY } from 'utils/channel';
import { isProductionMode } from 'utils/utils';

axios.interceptors.request.use(
  (config) => {
    store.dispatch(pending());
    return {
      ...config,
      headers: {
        ...config?.headers,
        // Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    // process 401
    const { data } = response;
    if (data.statusCode === 401) {
      store.dispatch(userSignOutSuccess());
      store.dispatch(success());
    } else {
      store.dispatch(success());
    }
    return response;
  },
  (error) => {
    const { response } = error;
    if (response) {
      const { data } = response;
      if (data.statusCode === 401) {
        store.dispatch(userSignOutSuccess());
        store.dispatch(success());
      }
    }
    store.dispatch(fail(error.message));
    return Promise.reject(error);
  },
);

function App() {
  const { isLogin, clearUser } = useUser();
  const { isLoading, error } = useLoading();
  const toastRef = useRef();
  // const channelService = new ChannelService();

  useEffect(() => {
    clearUser();
  }, [clearUser]);

  useEffect(() => {
    if (error) {
      const toast = { mode: 'error', message: error };
      toastRef.current.addMessage(toast);
    }
  }, [error]);

  useResetScaleWithResize();

  // NOTE: 채널톡 서비스 시작
  // useEffect(() => {
  //   if (isProductionMode()) {
  //     channelService.boot({
  //       pluginKey: CHANNEL_PLUGIN_KEY,
  //     });
  //   } else {
  //     // nothing
  //   }
  // });

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          {isLogin && <Route path="*" element={<Private />} />}
          <Route path="/" element={<Login />} />
          <Route path={menu.login.link} element={<Login />} />
          <Route path={menu.password.link} element={<Password />} />
          <Route path={menu.changepassword.link} element={<ChangePassword />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ScrollToTop>
      <Modal show={isLoading} loading>
        <Background>
          <Loading>
            <Spin color="red" />
          </Loading>
        </Background>
      </Modal>
      <Toast ref={toastRef} />
    </Router>
  );
}

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
`;

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 10px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;
`;

export default App;

function useResetScaleWithResize() {
  const siteWidth = 1370;
  const [width, setWidth] = useState(window.outerWidth);

  useEffect(() => {
    const resize = () => {
      setWidth(window.outerWidth);
    };

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  useEffect(() => {
    if (width >= siteWidth) {
      document
        .querySelector('meta[name="viewport"]')
        .setAttribute('content', 'width=device-width, initial-scale=1');
    } else {
      const scale = width / siteWidth;
      document
        .querySelector('meta[name="viewport"]')
        .setAttribute(
          'content',
          'width=' + siteWidth + ', initial-scale=' + scale + '',
        );
    }
  }, [width]);
}
