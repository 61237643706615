import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Input, Select } from 'components/atoms';
import { usePropContext } from 'context/PropContext';
import { PropContextProvider } from 'context/PropContext';
import {
  EstateAprvYear,
  EstateGap,
  EstateIsPrcCnt,
  EstatePrcDescRatio,
  EstateSpcTypeCd,
  EstateTotHsehCnt,
  EstateType1Count,
  EstateType2Ratio,
  getValueFromLabel,
} from 'pages/analysis/analysis/EstateObjectTableConst';
import { getEstateAddrList } from 'utils/api';
import TagsInput from 'components/atoms/input/TagsInput';

const SearchEstateObjectForm = ({
  // default value
  spc_type_cd,
  tot_hseh_cnt,
  aprv_year,
  type1_count,
  // upper_prc_info,
  // lower_prc_info,
  gap,
  is_prc_cnt,
  prc_desc_ratio,
  type2_ratio,
}) => {
  // 0. states
  const [sido, setSido] = useState();
  const [gungu, setGungu] = useState();
  const [dong, setDong] = useState();
  // const [address, setAddress] = useState([]);
  const [includedAddress, setIncludedAddress] = useState([]);
  const [excludedAddress, setExcludedAddress] = useState([]);
  const [lowerPrice, setLowerPrice] = useState();
  const [upperPrice, setUpperPrice] = useState();

  const [EstateSidoAddrList, setEstateSidoAddrList] = useState([
    { id: 'sido_addr_0', label: '---', value: null },
  ]);
  const [EstateGunguAddrList, setEstateGunguAddrList] = useState([
    { id: 'gungu_addr_0', label: '---', value: null },
  ]);
  const [EstateDongAddrList, setEstateDongAddrList] = useState([
    { id: 'dong_addr_0', label: '---', value: null },
  ]);

  // 1. original handler & options
  const { handleOption } = usePropContext();
  // 2. useEffect
  useEffect(() => {
    getEstateObjectAddrList();
  }, [sido, gungu]);
  // 3. handler
  const handleSido = (change) => {
    const target = getValueFromLabel(EstateSidoAddrList, change.target.value);
    setSido(target);
    setGungu(null); // initialize gungu
    setDong(null); // initialize dong
  };

  const handleGungu = (change) => {
    const target = getValueFromLabel(EstateGunguAddrList, change.target.value);
    setGungu(target);
    setDong(null); //initialize dong
  };

  const handleDong = (change) => {
    const target = getValueFromLabel(EstateDongAddrList, change.target.value);
    setDong(target);
  };

  const handleIncludedRegion = () => {
    const addr = [sido, gungu, dong]
      .filter((e) => {
        return e;
      })
      .join('_');
    // bugfix
    if (addr.length > 0) {
      const changed = Array.from(new Set([...includedAddress, addr]));
      handleIncludedTags(changed);
    }
  };

  const handleExcludedRegion = () => {
    const addr = [sido, gungu, dong]
      .filter((e) => {
        return e;
      })
      .join('_');
    // bugfix
    if (addr.length > 0) {
      const changed = Array.from(new Set([...excludedAddress, addr]));
      handleExcludedTags(changed);
    }
  };

  const handleIncludedTags = (tags) => {
    setIncludedAddress(tags);
    handleOption({
      addr: tags,
    });
  };

  const handleExcludedTags = (tags) => {
    setExcludedAddress(tags);
    handleOption({
      exAddr: tags,
    });
  };

  const handleSpcTypeCd = (change) => {
    const target = getValueFromLabel(EstateSpcTypeCd, change.target.value);
    handleOption({
      spc_type_cd: target,
    });
  };

  const handleTotHsehCnt = (change) => {
    const target = getValueFromLabel(EstateTotHsehCnt, change.target.value);
    handleOption({
      tot_hseh_cnt: target,
    });
  };

  const handleAprvYear = (change) => {
    const target = getValueFromLabel(EstateAprvYear, change.target.value);
    handleOption({
      aprv_year: target,
    });
  };

  const handleType1Count = (change) => {
    const target = getValueFromLabel(EstateType1Count, change.target.value);
    handleOption({
      type1_count: target,
    });
  };

  const handleUpperPrcInfo = (change) => {
    const target = change.target.value;
    setUpperPrice(target);
    handleOption({
      upper_prc_info: target,
    });
  };

  const handleLowerPrcInfo = (change) => {
    const target = change.target.value;
    setLowerPrice(target);
    handleOption({
      lower_prc_info: target,
    });
  };

  const handleGap = (change) => {
    const target = getValueFromLabel(EstateGap, change.target.value);
    handleOption({
      gap: target,
    });
  };

  const handleIsPrcCnt = (change) => {
    const target = getValueFromLabel(EstateIsPrcCnt, change.target.value);
    handleOption({
      is_prc_cnt: target,
    });
  };

  const handlePrcDescRatio = (change) => {
    const target = getValueFromLabel(EstatePrcDescRatio, change.target.value);
    handleOption({
      prc_desc_ratio: target,
    });
  };

  const handleType2Ratio = (change) => {
    const target = getValueFromLabel(EstateType2Ratio, change.target.value);
    handleOption({
      type2_ratio: target,
    });
  };

  // 4. api wrapper
  const getEstateObjectAddrList = async () => {
    const ret = await getEstateAddrList({
      sido,
      gungu,
    });
    const target = ret?.results;
    if (sido && gungu) {
      setEstateDongAddrList(
        [{ id: 'dong_addr_0', label: '---', value: null }].concat(
          target?.items.map((e, i) => {
            return {
              id: `dong_addr_${i + 1}`,
              label: e,
              value: e,
            };
          }),
        ),
      );
    } else if (sido) {
      setEstateGunguAddrList(
        [{ id: 'gungu_addr_0', label: '---', value: null }].concat(
          target?.items.map((e, i) => {
            return {
              id: `gungu_addr_${i + 1}`,
              label: e,
              value: e,
            };
          }),
        ),
      );
      setEstateDongAddrList([{ id: 'dong_addr_0', label: '---', value: null }]);
    } else {
      setEstateSidoAddrList(
        [{ id: 'sido_addr_0', label: '---', value: null }].concat(
          target?.items.map((e, i) => {
            return {
              id: `sido_addr_${i + 1}`,
              label: e,
              value: e,
            };
          }),
        ),
      );
    }
  };

  return (
    <Container>
      <RegionFilter>
        <FormTitle>지역필터</FormTitle>
        <FormSection>
          <div style={{ width: '120px' }}>
            <SubTitle>지역(도,시)</SubTitle>
            <Select
              name="sido"
              options={EstateSidoAddrList.map((item) => {
                const { id, label, value } = item;
                return {
                  id,
                  value,
                  name: label,
                };
              })}
              onChange={handleSido}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div style={{ width: '150px' }}>
            <SubTitle>지역(군,구)</SubTitle>
            <Select
              name="gungu"
              options={EstateGunguAddrList.map((item) => {
                const { id, label, value } = item;
                return {
                  id,
                  value,
                  name: label,
                };
              })}
              onChange={handleGungu}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div style={{ width: '120px' }}>
            <SubTitle>지역(동)</SubTitle>
            <Select
              name="dong"
              options={EstateDongAddrList.map((item) => {
                const { id, label, value } = item;
                return {
                  id,
                  value,
                  name: label,
                };
              })}
              onChange={handleDong}
              style={{ cursor: 'pointer' }}
            />
          </div>
          {/* 지역추가/제외 */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.4rem',
            }}
          >
            <div>
              <SubTitle>&nbsp;</SubTitle>
              <Button
                type="button"
                name="region"
                onClick={() => {
                  handleIncludedRegion(true);
                }}
              >
                지역추가
              </Button>
            </div>
            <div>
              <ExcludedButton
                type="button"
                name="region"
                onClick={() => {
                  handleExcludedRegion(false);
                }}
              >
                지역제외
              </ExcludedButton>
            </div>
          </div>
          {/* 지역추가/제외 tags-input */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.4rem',
              marginLeft: '2.5rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <PropContextProvider
                value={{
                  handleTags: handleIncludedTags,
                }}
              >
                <TagsInput
                  items={includedAddress}
                  isExcludedType={false}
                  placeHolder="지역을 추가해주세요."
                />
              </PropContextProvider>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <PropContextProvider
                value={{
                  handleTags: handleExcludedTags,
                }}
              >
                <TagsInput
                  items={excludedAddress}
                  isExcludedType={true}
                  placeHolder="지역을 제외시켜주세요."
                />
              </PropContextProvider>
            </div>
          </div>
        </FormSection>
      </RegionFilter>
      <BuildingFilter>
        <FormTitle>단지필터</FormTitle>
        <FormSection>
          <div style={{ width: '120px' }}>
            <SubTitle>평형타입</SubTitle>
            <Select
              name="spcTypeCd"
              options={EstateSpcTypeCd.map((item) => {
                const { id, label, value } = item;
                return {
                  id,
                  value,
                  name: label,
                };
              })}
              defaultValue={spc_type_cd}
              onChange={handleSpcTypeCd}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div style={{ width: '120px' }}>
            <SubTitle>세대수</SubTitle>
            <Select
              name="totHsehCnt"
              options={EstateTotHsehCnt.map((item) => {
                const { id, label, value } = item;
                return {
                  id,
                  value,
                  name: label,
                };
              })}
              defaultValue={tot_hseh_cnt}
              onChange={handleTotHsehCnt}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div style={{ width: '120px' }}>
            <SubTitle>연식</SubTitle>
            <Select
              name="aprvYear"
              options={EstateAprvYear.map((item) => {
                const { id, label, value } = item;
                return {
                  id,
                  value,
                  name: label,
                };
              })}
              defaultValue={aprv_year}
              onChange={handleAprvYear}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div style={{ width: '120px' }}>
            <SubTitle>매매건수</SubTitle>
            <Select
              name="type1Count"
              options={EstateType1Count.map((item) => {
                const { id, label, value } = item;
                return {
                  id,
                  value,
                  name: label,
                };
              })}
              defaultValue={type1_count}
              onChange={handleType1Count}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </FormSection>
      </BuildingFilter>
      <ArticleFilter>
        <FormTitle>매물필터</FormTitle>
        <FormSection>
          <div style={{ width: '120px' }}>
            <SubTitle>가격대(최저, 만원)</SubTitle>
            <Input
              name="lowerPrcInfo"
              value={lowerPrice}
              width="120px"
              onChange={handleLowerPrcInfo}
              style={{
                fontSize: '0.8rem',
                cursor: 'pointer',
                height: '37px',
                paddingLeft: '16px',
                border: '1px solid #E4E4E4',
              }}
            />
          </div>

          <div style={{ width: '120px' }}>
            <SubTitle>가격대(최고, 만원)</SubTitle>
            <Input
              name="upperPrcInfo"
              value={upperPrice}
              width="120px"
              onChange={handleUpperPrcInfo}
              style={{
                fontSize: '0.8rem',
                cursor: 'pointer',
                height: '37px',
                paddingLeft: '16px',
                border: '1px solid #E4E4E4',
              }}
            />
          </div>
          <div style={{ width: '120px' }}>
            <SubTitle>갭</SubTitle>
            <Select
              name="gap"
              options={EstateGap.map((item) => {
                const { id, label, value } = item;
                return {
                  id,
                  value,
                  name: label,
                };
              })}
              defaultValue={gap}
              onChange={handleGap}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div style={{ width: '120px' }}>
            <SubTitle>전고점미만</SubTitle>
            <Select
              name="isPrcCnt"
              options={EstateIsPrcCnt.map((item) => {
                const { id, label, value } = item;
                return {
                  id,
                  value,
                  name: label,
                };
              })}
              defaultValue={is_prc_cnt}
              onChange={handleIsPrcCnt}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div style={{ width: '120px' }}>
            <SubTitle>하락율</SubTitle>
            <Select
              name="prcDescRatio"
              options={EstatePrcDescRatio.map((item) => {
                const { id, label, value } = item;
                return {
                  id,
                  value,
                  name: label,
                };
              })}
              defaultValue={prc_desc_ratio}
              onChange={handlePrcDescRatio}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div style={{ width: '120px' }}>
            <SubTitle>전세가율</SubTitle>
            <Select
              name="type2Ratio"
              options={EstateType2Ratio.map((item) => {
                const { id, label, value } = item;
                return {
                  id,
                  value,
                  name: label,
                };
              })}
              defaultValue={type2_ratio}
              onChange={handleType2Ratio}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </FormSection>
      </ArticleFilter>
    </Container>
  );
};

const Container = styled.form`
  flex-direction: row;
  font-size: 0.8rem;
  // padding: 10px;
  width: 100%;
  background-color: #fff;
  display: grid;
  grid-template:
    'rf rf rf'
    'bf af af'
    'bf af af' auto;
  column-gap: 0.4rem;
  row-gap: 2rem;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SubTitle = styled.div`
  font-size: 12px;
  color: #a3aed0;
  margin-bottom: 5px;
`;

const FormSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  column-gap: 0.4rem;
  align-items: center;
`;

const FormTitle = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: #2b3674;
  margin-bottom: 15px;
`;

const RegionFilter = styled.div`
  grid-area: rf;
`;

const BuildingFilter = styled.div`
  grid-area: bf;
`;

const ArticleFilter = styled.div`
  grid-area: af;
`;

const ExcludedButton = styled(Button)`
  background-color: #ff8800;
  border: 1px solid #ff8800;
`;

export default SearchEstateObjectForm;
