import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup/yup'; // 1.3.7
import * as yup from 'yup';
import { Alert, Message, Input, Button } from 'components/atoms';
import Footer from 'components/templates/layout/Footer';
import { useUser } from 'app/user';
import viewEye from 'assets/images/view.png';
import hiddenEye from 'assets/images/hidden.png';
import logo from 'assets/images/logo.png';
import { LMSSiteUrl, menu } from 'utils/constants';

const schema = yup.object({
  email: yup
    .string()
    .email('이메일 형식이 올바르지 않습니다.')
    .required('등록하신 이메일을 입력해주세요.'),
  password: yup.string().required(''),
});

const Login = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const { user, signin, clearUser } = useUser();
  const navigate = useNavigate();
  const [hidden, setHidden] = useState(true);

  const onSubmit = (data) => {
    signin(data);
  };

  useEffect(() => {
    if (user && user?.results?.token) {
      navigate(menu.analysis.estateObjectList.link);
    }
  }, [user]);

  const findPassword = () => {
    navigate(menu.password.link);
  };

  // 회원가입은 정기결제와 연동해서 진행한다.
  // 외부링크로 연결한다.
  const goToSignup = () => {
    // navigate(menu.signup.link);
    // 기존창에서 연다.
    window.location.href = LMSSiteUrl;
    clearUser();
  };

  const handleHidden = () => {
    setHidden(!hidden);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Logo src={logo} />
          <Title>
            <span
              style={{
                fontWeight: 300,
              }}
            >
              &nbsp;&nbsp;&nbsp;급매 탐색기
            </span>
          </Title>
        </div>
        <div>
          <div style={{ display: 'flex', marginTop: '' }}>
            <Text>이메일</Text>
            <span style={{ color: '#8800ff', fontWeight: 500 }}>&nbsp;*</span>
          </div>
          <Input
            name="email"
            ref={register}
            placeholder="등록된 이메일을 입력해주세요."
            variant={errors.email && 'danger'}
            style={{ cursor: 'pointer' }}
          />
          {errors.email && (
            <Message variant="danger">{errors.email.message}</Message>
          )}
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <Text>비밀번호</Text>
            <span style={{ color: '#8800ff', fontWeight: 500 }}>&nbsp;*</span>
          </div>
          <div style={{ position: 'relative' }}>
            <Input
              name="password"
              ref={register}
              placeholder="비밀번호를 입력해주세요."
              type={hidden ? 'password' : 'text'}
              variant={errors.password && 'danger'}
              style={{ cursor: 'pointer' }}
            />
            {hidden ? (
              <Icon onClick={handleHidden} src={hiddenEye} alt="" />
            ) : (
              <Icon onClick={handleHidden} src={viewEye} alt="" />
            )}
          </div>
          {errors.password && (
            <Message variant="danger">{errors.password.message}</Message>
          )}
        </div>
        {user.message !== '성공' && user.message !== '' && (
          <Alert variant="danger">{user.message}</Alert>
        )}
        <Button className="button">로그인</Button>
        <SubTitle>
          <Password onClick={findPassword}>비밀번호 재설정</Password>
          {/* <DisabledPassword onClick={(e) => e.preventDefault()}>
            비밀번호 찾기
          </DisabledPassword> */}
          <Line>|</Line>
          {/* Block self-signup process */}
          <Signup onClick={goToSignup}>회원가입 요청</Signup>
          {/* <DisabledSignup onClick={(e) => e.preventDefault()}>
            회원가입 요청
          </DisabledSignup> */}
        </SubTitle>
        <Caution>
          *본 서비스는 나눔마린 온라인 클래스 정기결제를 통해서 가입하신 후
          사용하실 수 있습니다. (처음 사용하시는 분들은 가입한 이메일을 통해
          비밀번호를 재설정하시고 사용해주세요.)
        </Caution>
      </Form>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 30rem;
  margin: auto;
  padding-top: 3rem;
  line-height: 1.5;
  font-size: 0.8rem;

  input {
    font-size: 13px;
    padding-left: 15px;
    height: 50px;
  }

  button {
    height: 50px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 12.8;
    margin-top: 20px;

    &:hover {
      transform: translateY(-1px);
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 40px;
  background-color: #fff;
  padding: 60px 40px;
  border-radius: 21px;
  box-shadow: 5px 5px 5px #eee;
`;

const Icon = styled.img`
  position: absolute;
  top: 18px;
  right: 15px;
  width: 19px;
  cursor: pointer;
`;

const Logo = styled.img`
  width: 150px;
`;

const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  letter-spacing: -0.03rem;
`;

const SubTitle = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 10px;
`;

const Password = styled.a`
  color: #495057;
  font-size: 0.8rem;
  letter-spacing: -0.03rem;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #8800ff;
  }
`;

const Line = styled.div`
  color: #495057;
`;

const Signup = styled.a`
  color: #495057;
  font-size: 0.8rem;
  letter-spacing: -0.03rem;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #8800ff;
  }
`;

const DisabledSignup = styled.a`
  color: #9fa3a7;
  font-size: 0.8rem;
  letter-spacing: -0.03rem;
  font-weight: 500;
`;

const DisabledPassword = styled.a`
  color: #9fa3a7;
  font-size: 0.8rem;
  letter-spacing: -0.03rem;
  font-weight: 500;
`;

const Caution = styled.div`
  font-size: 11px;
  // color: #a3aed0;
  color: #8800ff;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
`;

export default Login;
