export const menu = {
  login: {
    link: '/login',
  },
  password: {
    link: '/password',
  },
  changepassword: {
    link: '/changepassword/:token',
  },
  analysis: {
    name: '부동산 데이터 분석',
    estateObjectList: {
      name: '부동산 매물 분석 현황',
      link: '/analysis/estate',
    },
  },
};

export const menuList = [
  {
    name: menu.analysis.name,
    child: [
      {
        name: menu.analysis.estateObjectList.name,
        link: menu.analysis.estateObjectList.link,
      },
    ],
  },
];
