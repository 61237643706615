import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const TrendChartBox = ({ item, col }) => {
  // chartoption
  const [chartOption, setChartOption] = useState({
    chart: {
      type: 'column',
      width: '100',
      height: '40',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      visible: false,
      categories: [],
    },
    yAxis: [
      {
        visible: false,
        title: {
          text: '',
        },
      },
      {
        visible: false,
        title: {
          text: '',
        },
        opposite: true,
      },
    ],
    colors: ['#8800ff'],
    tooltip: {
      enabled: false,
    },
    // plotOptions: {
    //   column: {
    //     stacking: 'percent',
    //   },
    // },
    series: [],
  });

  // setting ( type1_min_prc_info )
  useEffect(() => {
    setChartOption({
      series: {
        name: '',
        type: 'spline',
        yAxis: 1,
        data: item?.analysis[col]
          ? item.analysis[col].map((e) => {
              return parseInt(e);
            })
          : [],
      },
    });
  }, []);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOption} />
    </div>
  );
};

TrendChartBox.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

export default TrendChartBox;
