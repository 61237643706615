import React from 'react';
import styled from 'styled-components';
import { Select } from 'components/atoms';
import { usePropContext } from 'context/PropContext';
import {
  EstateObjectTableSizeRules,
  EstateObjectTableSortFields,
  EstateObjectTableSortRules,
  getValueFromLabel,
} from 'pages/analysis/analysis/EstateObjectTableConst';

const SelectSizeSortOption = () => {
  const { rules, fields, sizeRules, handleOption } = usePropContext();

  const handleOptionRule = (change) => {
    handleOption({
      sort_rule: getValueFromLabel(
        EstateObjectTableSortRules,
        change.target.value,
      ),
    });
  };

  const handleOptionField = (change) => {
    handleOption({
      sort_field: getValueFromLabel(
        EstateObjectTableSortFields,
        change.target.value,
      ),
    });
  };

  const handleSizeOptionRule = (change) => {
    handleOption({
      page: 1,
      size: getValueFromLabel(EstateObjectTableSizeRules, change.target.value),
    });
  };

  return (
    <Container>
      <Content
        style={{
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <div>
          <SubTitle>정렬대상</SubTitle>
          <Select
            name="sort-field"
            options={fields}
            onChange={handleOptionField}
            style={{ width: '100px', cursor: 'pointer' }}
          />
        </div>
        <div>
          <SubTitle>정렬순서</SubTitle>
          <Select
            name="sort-rule"
            options={rules}
            onChange={handleOptionRule}
            style={{ width: '100px', cursor: 'pointer' }}
          />
        </div>
        <div>
          <SubTitle>결과개수</SubTitle>
          <Select
            name="size-rule"
            options={sizeRules}
            onChange={handleSizeOptionRule}
            style={{ width: '100px', cursor: 'pointer' }}
          />
        </div>
      </Content>
    </Container>
  );
};

const Container = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 3rem;
  font-size: 0.8rem;
`;

const SubTitle = styled.div`
  font-size: 12px;
  color: #a3aed0;
  margin-bottom: 5px;
`;

const Content = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export default SelectSizeSortOption;
