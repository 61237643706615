import { usePropContext } from 'context/PropContext';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const TagsInput = ({ items, isExcludedType, placeHolder }) => {
  const [tags, setTags] = useState([]);
  const { handleTags } = usePropContext();

  useEffect(() => {
    setTags(items ? items : []);
  }, [items]);

  const getTagName = (name) => {
    if (name) {
      return name.replace(/\_/gi, ' ');
    } else {
      return name;
    }
  };

  const removeTag = (index) => {
    const changed = tags.filter((el, i) => i !== index);
    setTags(changed);
    // connect to server (api)
    handleTags(changed);
  };

  return (
    <Container>
      {!tags.length > 0 && <EmptySpan>{placeHolder}</EmptySpan>}
      {isExcludedType
        ? tags.map((tag, index) => (
            <ExcludedTagItem key={index}>
              <span className="text">{getTagName(tag)}</span>
              <span className="close" onClick={() => removeTag(index)}>
                &times;
              </span>
            </ExcludedTagItem>
          ))
        : tags.map((tag, index) => (
            <IncludedTagItem key={index}>
              <span className="text">{getTagName(tag)}</span>
              <span className="close" onClick={() => removeTag(index)}>
                &times;
              </span>
            </IncludedTagItem>
          ))}
    </Container>
  );
};

const Container = styled.div`
  border: 1.5px solid #e4e4e4;
  border-radius: 6px;
  padding: 0.5em;
  width: min(80vw, 600px);
  min-height: 50px;
  // margin-top: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
`;

const IncludedTagItem = styled.div`
  // background-color: rgb(218, 216, 216);
  background-color: #8800ff;
  color: #fff;
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 20px;
  .close {
    height: 20px;
    width: 20px;
    background-color: rgb(48, 48, 48);
    // background-color: #e9ecef;
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5em;
    font-size: 15px;
    cursor: pointer;
  }
`;
const ExcludedTagItem = styled.div`
  // background-color: rgb(218, 216, 216);
  // background-color: #8800ff;
  background-color: #ff8800;
  color: #fff;
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 20px;
  .close {
    height: 20px;
    width: 20px;
    background-color: rgb(48, 48, 48);
    // background-color: #e9ecef;
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5em;
    font-size: 15px;
    cursor: pointer;
  }
`;

const EmptySpan = styled.div`
  width: 100%;
  height: 100%;
  color: #a3aed0;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default TagsInput;
