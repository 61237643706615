import React, { forwardRef, useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import chevron from 'assets/images/chevron-down.png';
import dropdown from 'assets/images/drop_down.png';

const DefaultSelect = forwardRef(
  (
    {
      edit,
      onChange,
      options,
      children,
      name,
      variant,
      setSelectValue,
      selectedValue,
      type,
      ...rest
    },
    ref,
  ) => {
    const [selected, setSeleted] = useState();
    const [inputValue, setInputValue] = useState('');
    const [selectSize, setSelectSize] = useState('');
    const container = useRef();

    const handleSelect = (e) => {
      const { name, value } = e.target;
      setSeleted(value);
      if (type === 'keyword') {
        setSelectValue(value);
      }

      if (onChange) {
        onChange({
          ...e,
          target: {
            ...e.target,
            name,
            value: edit && value === edit?.value ? inputValue : value,
          },
        });
      }
    };

    const handleInput = (e) => {
      const { value } = e.target;
      setInputValue(value);

      if (onChange) {
        onChange(e);
      }
    };

    const handleClick = (e) => {
      const select = options[0]?.value;
      setInputValue('');
      setSeleted(select);
      onChange({
        ...e,
        target: {
          ...e.target,
          name,
          value: select,
        },
      });
    };

    useEffect(() => {
      setSelectSize(rest.width || `${container.current?.offsetWidth}px`);
    }, [rest.width]);

    return (
      <Container variant={variant} ref={container} name={name}>
        {edit && selected === edit?.value && edit?.label !== '전체' ? (
          <Content width={selectSize}>
            <Input
              autoFocus
              name={name}
              placeholder={edit.placeholder || edit.label}
              onChange={handleInput}
              width={selectSize}
              ref={ref}
            />
            <button type="button" onClick={handleClick}>
              <svg width="10" height="10">
                <path d="M5 4L8.5.3l1 1.1L6.2 5l3.5 3.6-1 1L5 6.1 1.4 9.6l-1-1L3.9 5 .4 1.5l1.1-1L5 3.8z" />
              </svg>
            </button>
          </Content>
        ) : (
          <Select
            name={name}
            onChange={handleSelect}
            width={selectSize}
            ref={ref}
            {...rest}
          >
            {edit && <option value={edit.value}>{edit.label}</option>}
            {options?.map((el, idx) => (
              <option
                // NOTE: remove warning (react v18)
                // NOTE: react use value instead of selected
                // selected={el.id === selectedValue ? true : false}
                key={`${el.value}-${idx.toString()}`}
                value={el.id}
              >
                {el.name}
              </option>
            ))}
            {children}
          </Select>
        )}
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  width: ${({ width }) => width};
  padding: 1px;
  line-height: 1.5;
  height: ${({ name }) => (name === 'itemSearch' ? '2.7rem' : '2.2rem')};
  border: 1.5px solid #e4e4e4;
  border-radius: 6px;
  background-color: white;

  &:focus-within {
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.3);
  }

  ${({ variant }) =>
    variant === 'danger' &&
    css`
      border: 1px solid red;
      &:focus-within {
        box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.3);
      }
    `}
`;

const Content = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  & > button {
    padding: 0;
    width: 20px;
    height: 100%;
    background: inherit;
    border: none;
    cursor: pointer;
  }
`;

const Input = styled.input`
  display: inline-block;
  ${({ width }) =>
    css`
      width: calc(${width} - 20px);
    `};
  height: 100%;
  color: #495057;
  padding: 0.375rem 0rem 0.375rem 0.75rem;
  background-color: inherit;
  border: none;
  outline: 0;

  &:disabled {
    opacity: 1;
    background-color: #e9ecef;
  }
`;

const Select = styled.select`
  width: ${({ width }) => width};
  padding: 0.375rem 1.2rem 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  color: #495057;
  background-color: inherit;
  background-clip: padding-box;
  background-image: url(${dropdown});
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: right 5px top 50%;
  border: none;
  appearance: none;
  outline: 0;

  &:disabled {
    opacity: 1;
    background-color: #e9ecef;
  }
`;

DefaultSelect.propTypes = {
  edit: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  variant: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  children: PropTypes.node,
};

DefaultSelect.defaultProps = {
  edit: null,
  onChange: () => {},
  variant: null,
  name: null,
  options: null,
  children: null,
};

export default React.memo(DefaultSelect);
