import { range } from 'lodash';

export const EstateObjectTableSizeRules = [
  { id: 'size_rule', name: '---', value: null },
  { id: 'size_10', value: 10, name: '10개' },
  { id: 'size_30', value: 30, name: '30개' },
  { id: 'size_50', value: 50, name: '50개' },
  { id: 'size_100', value: 100, name: '100개' },
];

export const EstateObjectTableCols = [
  { label: '지역', col: 'addr' },
  { label: '단지명', col: 'hscp_nm' },
  { label: '세대수', col: 'tot_hseh_cnt' },
  { label: '평형타입', col: 'spc_type_cd' },
  { label: '실거래최고가', col: 'global_max_prc_info' },
  { label: '호가최고가', col: 'type1_max_prc_info' },
  { label: '호가최저가', col: 'type1_min_prc_info' },
  { label: '전세최고가', col: 'type2_max_prc_info' },
  { label: '전세최저가', col: 'type2_min_prc_info' },
  { label: '하락율', col: 'prc_desc_ratio' },
  { label: '갭', col: 'gap' },
  { label: '전세가율', col: 'type2_ratio' },
  { label: '가격하락금액', col: 'prc_desc' },
  // { label: '전고점미만개수(2022)', col: '2022_min_prc_cnt' },
  // { label: '전고점미만개수(2021)', col: '2021_min_prc_cnt' },
  { label: '매물가격', col: 'prc_info' },
  // { label: '매물동수', col: 'bild_nm' },
  { label: '매물층수', col: 'flr_info' },
  { label: '연식', col: 'aprv_year' },
  { label: '매물링크', col: 'atcl_url' },
];

export const EstateObjectTableSortRules = [
  { id: 'sort_rule', name: '---', value: null },
  { id: 'desc', value: 'desc', name: '내림차순' },
  { id: 'asc', value: 'asc', name: '오름차순' },
];

export const EstateObjectTableSortFields = [
  { id: 'sort_field', name: '---', value: null },
  { id: 'tot_hseh_cnt', value: 'tot_hseh_cnt', name: '세대수' },
  {
    id: 'global_max_prc_info',
    value: 'global_max_prc_info',
    name: '실거래최고가',
  },
  { id: 'type1_max_prc_info', value: 'type1_max_prc_info', name: '호가최고가' },
  { id: 'type1_min_prc_info', value: 'type1_min_prc_info', name: '호가최저가' },
  { id: 'type2_max_prc_info', value: 'type2_max_prc_info', name: '전세최고가' },
  { id: 'type2_min_prc_info', value: 'type2_min_prc_info', name: '전세최저가' },
  { id: 'prc_desc_ratio', value: 'prc_desc_ratio', name: '하락율' },
  { id: 'gap', value: 'gap', name: '갭' },
  { id: 'type2_ratio', value: 'type2_ratio', name: '전세가율' },
  { id: 'prc_desc', value: 'prc_desc', name: '가격하락금액' },
  { id: 'prc_info', value: 'prc_info', name: '매물가격' },
  { id: 'aprv_year', value: 'aprv_year', name: '연식' },
];

export const EstateSpcTypeCd = [
  { id: 'spc_type_cd_0', label: '---', value: null },
  { id: 'spc_type_cd_1', label: '25평형', value: '25pt' },
  { id: 'spc_type_cd_2', label: '32평형', value: '32pt' },
];

export const EstateTotHsehCnt = [
  { id: 'tot_hseh_cnt_0', label: '---', value: null },
  { id: 'tot_hseh_cnt_1', label: '300세대 이상', value: 300 },
  { id: 'tot_hseh_cnt_2', label: '500세대 이상', value: 500 },
  { id: 'tot_hseh_cnt_3', label: '1000세대 이상', value: 1000 },
];

export const EstateAprvYear = [
  { id: 'aprv_year_0', label: '---', value: null },
  { id: 'aprv_year_1', label: '3년차 이하', value: 3 },
  { id: 'aprv_year_2', label: '5년차 이하', value: 5 },
  { id: 'aprv_year_3', label: '10년차 이하', value: 10 },
  { id: 'aprv_year_4', label: '20년차 이하', value: 20 },
  { id: 'aprv_year_5', label: '20년차 초과', value: 99 },
];

export const EstateType1Count = [
  { id: 'type1_count_0', label: '---', value: null },
  { id: 'type1_count_1', label: '10건 이하', value: 10 },
  { id: 'type1_count_2', label: '50건 이하', value: 50 },
  { id: 'type1_count_3', label: '50건 초과', value: 99 },
];

export const EstateUpperPrcInfo = [
  { id: 'upper_prc_info_0', label: '---', value: null },
  { id: 'upper_prc_info_1', label: '5억 이하', value: 50000 },
  { id: 'upper_prc_info_2', label: '10억 이하', value: 100000 },
  { id: 'upper_prc_info_3', label: '20억 이하', value: 200000 },
  { id: 'upper_prc_info_4', label: '50억 이하', value: 500000 },
  { id: 'upper_prc_info_5', label: '100억 이하', value: 1000000 },
];

export const EstateLowerPrcInfo = [
  { id: 'lower_prc_info_0', label: '---', value: null },
  { id: 'lower_prc_info_1', label: '1억 이상', value: 10000 },
  { id: 'lower_prc_info_2', label: '5억 이상', value: 50000 },
  { id: 'lower_prc_info_3', label: '10억 이상', value: 100000 },
  { id: 'lower_prc_info_4', label: '20억 이상', value: 200000 },
  { id: 'lower_prc_info_5', label: '50억 이상', value: 500000 },
];

export const EstateGap = [
  { id: 'gap_0', label: '---', value: null },
  { id: 'gap_1', label: '1억 이하', value: 10000 },
  { id: 'gap_2', label: '3억 이하', value: 30000 },
  { id: 'gap_3', label: '5억 이하', value: 50000 },
  { id: 'gap_4', label: '10억 이하', value: 100000 },
  { id: 'gap_5', label: '20억 이하', value: 200000 },
];

export const EstateIsPrcCnt = [
  { id: 'is_prc_cnt_0', label: '---', value: null },
  { id: 'is_prc_cnt_1', label: '존재', value: 'true' },
];

export const EstatePrcDescRatio = [
  {
    id: 'prc_desc_ratio_0',
    label: '---',
    value: null,
  },
].concat(
  range(10, 100, 10).map((item, i) => {
    return {
      id: `prc_desc_ratio_${i + 1}`,
      label: `${item}% 이상`,
      value: item,
    };
  }),
);
// .concat([
//   {
//     id: 'prc_desc_ratio_11',
//     label: '90% 초과',
//     value: 99,
//   },
// ]);

export const EstateType2Ratio = [
  {
    id: 'type_2_ratio_0',
    label: '---',
    value: null,
  },
].concat(
  range(10, 100, 10).map((item, i) => {
    return {
      id: `type_2_ratio_${i + 1}`,
      label: `${item}% 이상`,
      value: item,
    };
  }),
);
// .concat([
//   {
//     id: 'type_2_ratio_11',
//     label: '90% 초과',
//     value: 99,
//   },
// ]);

export const getValueFromLabel = (keymap, id) => {
  const ret = keymap.filter((item) => {
    return item.id === id;
  });
  //
  if (ret?.length > 0) {
    return ret[0].value;
  } else {
    return null;
  }
};
