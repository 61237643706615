import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  return <div></div>;
  // return (
  //   <Container>
  //     <div></div>
  //     <Terms></Terms>
  //   </Container>
  // );
};

const Container = styled.footer`
  padding: 30px 0;
  color: #333;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
  line-height: 200%;
  letter-spacing: -0.03rem;

  .terms {
    color: #5e646d;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -0.03rem;
    &:hover {
      text-decoration: underline;
    }
  }

  .email {
    color: black;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: -0.03rem;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Terms = styled.div`
  justify-content: space-between;
  display: flex;
  width: 200px;
  margin: 0 auto;
  text-align: center;
`;

export default Footer;
