import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup/yup'; // 1.3.7
import * as yup from 'yup';
import { Message, Input, Button, Alert } from 'components/atoms';
import Footer from 'components/templates/layout/Footer';
import { postAccountUpdatePassword } from 'utils/api';
import { message } from 'hooks/useMessage';
import viewEye from 'assets/images/view.png';
import hiddenEye from 'assets/images/hidden.png';
import logo from 'assets/images/logo.png';
// import { ServiceTitle } from 'utils/constants';

const schema = yup.object({
  password: yup
    .string()
    .min(4, '비밀번호는 최소 4자 이상 입력해주세요.')
    .required('비밀번호를 입력해주세요.'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], '비밀번호가 일치하지 않습니다.')
    .required('비밀번호를 입력해주세요.'),
});

const ChangePassword = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [hidden01, setHidden01] = useState(true);
  const [hidden02, setHidden02] = useState(true);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate('/login');
  };

  const onSubmit = async (data) => {
    const res = await postAccountUpdatePassword({
      password: data.password,
    });
    if (res.statusCode === 200) {
      message.success('비밀번호가 변경되었습니다.');
      navigate('/login');
    } else {
      setErrorMessage(res.message);
    }
  };

  const handleHidden = () => {
    setHidden01(!hidden01);
  };

  const handleHiddenPassword = () => {
    setHidden02(!hidden02);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Logo src={logo} />
          <Title>
            <span
              style={{
                fontWeight: 300,
              }}
            >
              &nbsp;&nbsp;&nbsp;급매 탐색기
            </span>
          </Title>
        </div>
        <SubTitle>비밀번호 재설정</SubTitle>
        <div>
          <div style={{ display: 'flex' }}>
            <Text>새 비밀번호</Text>
            <span style={{ color: '#8800ff', fontWeight: 500 }}>&nbsp;*</span>
          </div>
          <div style={{ position: 'relative' }}>
            <Input
              name="password"
              ref={register}
              type={hidden01 ? 'password' : 'text'}
              placeholder="새 비밀번호"
              variant={errors.password && 'danger'}
              style={{ cursor: 'pointer' }}
            />
            {hidden01 ? (
              <Icon onClick={handleHidden} src={hiddenEye} alt="" />
            ) : (
              <Icon onClick={handleHidden} src={viewEye} alt="" />
            )}
          </div>
          {errors.password && (
            <Message variant="danger">{errors.password.message}</Message>
          )}
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <Text>새 비밀번호 확인</Text>
            <span style={{ color: '#8800ff', fontWeight: 500 }}>&nbsp;*</span>
          </div>
          <div style={{ position: 'relative' }}>
            <Input
              name="confirmPassword"
              ref={register}
              type={hidden02 ? 'password' : 'text'}
              placeholder="새 비밀번호 확인"
              variant={errors.confirmPassword && 'danger'}
              style={{ cursor: 'pointer' }}
            />
            {hidden02 ? (
              <Icon onClick={handleHiddenPassword} src={hiddenEye} alt="" />
            ) : (
              <Icon onClick={handleHiddenPassword} src={viewEye} alt="" />
            )}
          </div>
          {errors.confirmPassword && (
            <Message variant="danger">{errors.confirmPassword.message}</Message>
          )}
          {errorMessage && (
            <Alert style={{ marginTop: 20 }} variant="danger">
              {errorMessage}
            </Alert>
          )}
        </div>
        <ButtonContainer>
          <LoginButton onClick={goToLogin}>취소</LoginButton>
          <Button>완료</Button>
        </ButtonContainer>
      </Form>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 30rem;
  margin: auto;
  padding-top: 3rem;
  line-height: 1.5;
  font-size: 0.8rem;

  input {
    height: 50px;
    padding-left: 15px;
    font-size: 13px;
  }

  button {
    height: 50px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 12.8;
    margin-top: 20px;

    &:hover {
      transform: translateY(-1px);
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 40px;
  background-color: #fff;
  padding: 60px 40px;
  border-radius: 21px;
  box-shadow: 5px 5px 5px #eee;
`;

const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
`;

const SubTitle = styled.div`
  margin-top: -15px;
  margin-bottom: 36px;
  font-weight: 500;
  text-align: center;
  font-size: 14.5px;
  line-height: 22px;
`;

const Icon = styled.img`
  position: absolute;
  top: 18px;
  right: 15px;
  width: 19px;
  cursor: pointer;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  letter-spacing: -0.03rem;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  align-items: center;
`;

const LoginButton = styled.div`
  margin-top: 20px;
  height: 50px;
  border: 1px solid #8800ff;
  background-color: #fff;
  color: #8800ff;
  text-align: center;
  padding: 0.375rem 0.75rem;
  line-height: 33px;
  border-radius: 10px;
  display: inline-block;
  font-size: 12.8px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    transform: translateY(-1px);
  }
`;

const Logo = styled.img`
  width: 150px;
`;

export default ChangePassword;
